import React from 'react';
import {connect} from 'react-redux';
// import Fetcher from "../../actions/Fetcher";
import Messages from '../../components/Messages';
import common from '../../components/common';
import axios from 'axios';
import AssignCategories from '../../components/AssignCategories';

class SuppliersNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories : [],
      loading: true,
      categories: [],
      first_name:'', last_name:'', email:'', password:'', location: ''
    };
  }

  
  componentDidMount(){
    this.getCategories();
  }

  
  async getCategories() {
    const response = await axios.get(
      `${common.bs}/admin_categories.php?mode=didMount&action=list&page=1&per_page=100&delay=1&token=${this.props.token}`
    );
    this.setState({
      categories: response.data.data,
 
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async createJobCard(e) {
    e.preventDefault();
    let {first_name, last_name, email, password,location, selectedCategories} = this.state;
    if(selectedCategories.length ==0 )
    {
      alert("Please choose atleast 1 category");
      return;
    }
    let obj = {first_name, last_name, email, password,location, categories:selectedCategories.join(',')};
    let send_data = {
      data: obj,
    };

    let url = `${common.bs}/admin_suppliers.php?action=create&token=${this.props.token}`;
    const response = await axios.post(url, send_data);
    let data = response.data;
    if (data.ok) {
      this.setState({
        first_name:'', last_name:'', email:'', password:'', location: '',selectedCategories:[]
      });
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: data.msg || 'Supplier successfully.'}],
      });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to create Supplier'}],
      });
    }

    // if(response)
  }

  render() {
    // const {totalRows, loading} = this.state;

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h2>{'New Supplier'}</h2>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.createJobCard.bind(this)}>
              <h3>Supplier Details</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      value={this.state.first_name}
                      type="text"
                      required
                      name="first_name"
                      id="first_name"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                  {/* </div>
                </div> */}
                  <br />
                  {/* <div className="row"> */}
                  {/* <div className="col-md-12"> */}
                  <div className="form-group">
                    <label>Last Name</label>

                    <input
                      value={this.state.last_name}
                    
                      required
                      name="last_name"
                      id="last_name"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>
                  <br />

                  <div className="form-group">
                    <label>Location</label>
                    <textarea
                      value={this.state.location}
                      required
                      name="location"
                      id="location"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    ></textarea>
                  </div>
                  <br />



                  <div className="form-group">
                    <label>Email</label>

                    <input
                      value={this.state.email}
                    
                      required
                      name="email"
                      id="email"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>
                  <br />

                  <div className="form-group">
                    <label>Passowrd</label>

                    <input
                      value={this.state.password}
                    
                      required
                      name="password"
                      id="password"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <AssignCategories onSelected={(items)=>{
                      this.setState({
                        selectedCategories : items
                      })
                    }} categories={(this.state.categories.filter(item =>{
                       if( parseInt(item.status) === 1 )return true;return false;
                    })) || []} />
                  </div>
                  <br />
                </div>
              </div>
              {
                JSON.stringify(this.state.selectedCategories)
              }

              <p>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Create New Supplier Account"
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(SuppliersNew);
