import React from 'react';
import {connect} from 'react-redux';
// import Fetcher from "../../actions/Fetcher";
import Messages from '../../components/Messages';
import common from '../../components/common';
import axios from 'axios';
import { useParams } from "react-router-dom";

class CategoryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
       
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  componentDidMount(){ 
    this.getCategory();
  }

   
  async getCategory() {
    const response = await axios.get(
      `${common.bs}/admin_categories.php?mode=didMount&action=get_single&id=${this.props.id}&token=${this.props.token}`
    );
    this.setState({
      image: response?.data?.category?.image, 
      name: response?.data?.category?.name,
 
    });
  }


  handleFileChange  (e){
    
    this.setState({
      files : e.target.files
    })
  };

  async createProduct(e) {
    e.preventDefault();
    if(this.state.saving)return false
    this.setState({saving:true});
    let {files, name} = this.state;
    const filedata = new FormData();
    
    filedata.append('name', name)
    filedata.append('id', this.props.id) 
    
    if(files && files.length)
    {
      let file = files[0];
      filedata.append(`image`, file, file.name);
    }
    

    let url = `${common.bs}/admin_categories.php?action=update&token=${this.props.token}`;
    const response = await axios.post(url, filedata);
    let data = response.data;
    this.setState({
      saving:false
    })
    if (data.ok) {
     
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: data.msg || 'Product updated successfully.'}],
      });
      this.getCategory();

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to update Product'}],
      });
    }
  }

  render() {
    const {categories} = this.state;

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h2>{'Edit Category'}</h2>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.createProduct.bind(this)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Category Name</label>
                    <input
                      value={this.state.name}
                      type="text"
                      required
                      name="name"
                      id="name"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
         
                  
                
                  <div className="form-group">
                    <label>Image (optional)<br /><small>Leave blank if do not want to update image</small></label>

                    <input
                      value={this.state.imaged}
     
                      name="image"
                      id="image"
                      type='file'
                      accept="image/*" 
                      onChange={this.handleFileChange.bind(this)}
                      className="form-control"
                    />
                    <br />
                    <label>Prev selection:</label><br />
                    <img src={common.bs+'/..'+this.state.image} className='product-imga' />
                  </div>
  

 
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={this.state.saving ? 'please wait...' :"Update Category"}
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};


const CategoryEditParent = (props) => {
  
  const params = useParams();
 
  return <CategoryEdit {...props} id={params.id}  />
}

export default connect(mapStateToProps)(CategoryEditParent);
