import React from 'react';
import moment from 'moment';


const getFancyStatus = function(status){
  switch (status) {
    case "new":
      return "New";
    case 'with_suppliers':
      return 'Sent to Suppliers'
    case 'quote_selected':
      return "Quote Sent to Customer"
      case 'quote_rejected':
        return 'Quote Rejected';
        case 'payment_pending':
        return 'Payment Pending';
    case 'payment_done':
      return 'Payment Done';
    case 'quote_accepted':
      return 'Quote Accepted';
      case 'cancelled':
        return 'Cancelled';
        case 'complete':
          return 'Complete';
  }
  return status;
};
const findSupplier = function(supid, suppliers) {
  let sup = false;
  suppliers.map((item, i) => {
    if ("" + item.id === "" + supid) {
      sup = item;
    }
    return item;
  });
  return sup;
};
const getStatusColor = function (item) {
  if (
    item == "new" ||
    item == "accepted" ||
    item == 'assigned' || 
    item == 'quoted' || 
    item == "quote_selected" ||
    item == "with_suppliers"
  ) {
    return "primary";
  }

  if (
    item == "quote_rejected" ||
    item == "cancelled" ||
    item == "admin_reject" ||
    item == "user_reject" ||
    item == "quote_challenged"
  ) {
    return "danger";
  }

  if (item == "completed" 
  || item == "quote_accepted"
  || item == "user_accept"
  || item == "user_accept"
  || item == "complete"
  ) {
    return "success";
  }
  return 'primary'
};

const getOrderDataAr = function(data){
  let keys = Object.keys(data);
  let ar = [];
  keys.map((item, i) => {
    ar.push({ key: item, val: data[item] });
    return ar;
  });
  return ar;
};

const getOrderData = function(data) {
  return JSON.parse(data);
};

const getcategory= function( data)  {
  let tmp = JSON.parse(data ||'{}'); 
  return tmp.category;
}

const getFancyQuoteStatus = function(status)
{
  switch(status)
  {
    case 'assigned':return "Pending";
    case 'quoted':return "Quote Received";
    case 'admin_reject':return "Rejected by Admin";
    case 'admin_accept':return "Accepted by Admin";
    case 'user_reject':return "Rejected by Client";
    case 'user_accept':return "Accepted by Client";

  }
  return status;

}


const utils = {
  getFancyStatus,
  getFancyQuoteStatus,
  findSupplier,
  getStatusColor,
  getOrderDataAr,
  getOrderData,
  getcategory
};
export default utils;
