import React from 'react';
import {connect} from 'react-redux'; 
import common from '../components/common';
import Progress from '../components/Progress';
import axios from 'axios';
import moment from 'moment';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      loading:false
    };
  }

  componentDidMount(){
    this.fetchNotifications();
  }


  async fetchNotifications(){
this.setState({
  loading:true,
})
    let url = `${common.bs}/${this.props.user.role}_actions.php?action=getnotifications&token=${this.props.token}`;
    const response = await axios.get(url);
    let data = response.data;
    if (data.ok) {
      this.setState({
        notifications: data.data.notifications,
        loading:false
      });
    }else{
      this.setState({
        notifications:[],
        loading:false
      })
    }
  }
  

  render() {
    // const {totalRows, loading} = this.state;

    return (
      <div className="container-fluid dashboard-content">
                <Progress showing={this.state.loading} />

        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h2>{'Notifications'}</h2>
            
          
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Notification</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.notifications ?
                this.state.notifications.map((item, i)=>{
                  return(
                    <tr key={item.id}>
                      <td>
                        <strong>{item.title}</strong>
                        <br />
                        {item.msg}
                      </td>
                      <td>
                        {moment(item.created_at).format('lll')}
                      </td>
                    </tr>
                  )
                }) : false
              }
            </tbody>
          </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(Notifications);
