import React from 'react';
import {connect} from 'react-redux';
// import Fetcher from "../../actions/Fetcher";
import Messages from '../../components/Messages';
import common from '../../components/common';
import axios from 'axios';

class ProductNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      first_name:'', last_name:'', email:'', password:''
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  componentDidMount(){
    this.getCategories();
  }

  
  async getCategories() {
    const response = await axios.get(
      `${common.bs}/admin_categories.php?mode=didMount&action=list&page=1&per_page=100&delay=1&token=${this.props.token}`
    );
    this.setState({
      categories: response.data.data,
      loading: false,
    });
  }

  handleFileChange  (e){
    
    this.setState({
      files : e.target.files
    })
  };

  async createProduct(e) {
    
    e.preventDefault();
    if(this.state.saving)return false
    this.setState({saving:true});
    let {files, name, category_id} = this.state;
    const filedata = new FormData();
    let file = files[0];
    filedata.append(`image`, file, file.name);
    filedata.append('name', name)
    filedata.append('category_id', category_id)
    
    

    let url = `${common.bs}/admin_products.php?action=create&token=${this.props.token}`;
    const response = await axios.post(url, filedata);
    let data = response.data;
    this.setState({
      saving:false
    })
    if (data.ok) {
      this.setState({
        name:'',category_id:''
      });
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: data.msg || 'Product successfully.'}],
      });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to create Product'}],
      });
    }
  }

  render() {
    const {categories} = this.state;

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h2>{'New Product'}</h2>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.createProduct.bind(this)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Product Name</label>
                    <input
                      value={this.state.name}
                      type="text"
                      required
                      name="name"
                      id="name"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
        
                  <br />
                  <div className="form-group">
                    <label>Product Category</label>
                    <select
                      value={this.state.category_id}
                      
                      required
                      name="category_id"
                      id="category_id"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    ><option value={''}>Choose Category</option>
                    {
                      categories  && categories.map(item => <option key={item.id} value={item.id}>{item.name}</option>) 
                    }
                    </select>
                  </div>
                  <br />

                  
                
                  <div className="form-group">
                    <label>Image</label>

                    <input
                      value={this.state.image}
                      required
                      name="image"
                      id="image"
                      type='file'
                      accept="image/*" 
                      onChange={this.handleFileChange.bind(this)}
                      className="form-control"
                    />
                  </div>
  

 
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={this.state.saving ? 'please wait...' :"Create New Product"}
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(ProductNew);
