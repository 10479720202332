import React from 'react';
import {connect} from 'react-redux';
// import Fetcher from "../../actions/Fetcher";
import Messages from '../../components/Messages';
import common from '../../components/common';
import axios from 'axios';

class AvbobUsersNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      first_name:'', last_name:'', email:'', password:''
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async createJobCard(e) {
    e.preventDefault();
    let {first_name, last_name, email, password} = this.state;
    let obj = {first_name, last_name, email, password};
    let send_data = {
      data: obj,
    };

    let url = `${common.bs}/admin_avbobusers.php?action=create&token=${this.props.token}`;
    const response = await axios.post(url, send_data);
    let data = response.data;
    if (data.ok) {
      this.setState({
        first_name:'', last_name:'', email:'', password:''
      });
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: data.msg || 'Avbob Client successfully.'}],
      });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to create Client'}],
      });
    }

    // if(response)
  }

  render() {
    // const {totalRows, loading} = this.state;

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h2>{'New AVBOB Client'}</h2>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.createJobCard.bind(this)}>
              <h3>AVBOB Details</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      value={this.state.first_name}
                      type="text"
                      required
                      name="first_name"
                      id="first_name"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                  {/* </div>
                </div> */}
                  <br />
                  {/* <div className="row"> */}
                  {/* <div className="col-md-12"> */}
                  <div className="form-group">
                    <label>Last Name</label>

                    <input
                      value={this.state.last_name}
                    
                      required
                      name="last_name"
                      id="last_name"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>
                  <br />

                  <div className="form-group">
                    <label>Email</label>

                    <input
                      value={this.state.email}
                    
                      required
                      name="email"
                      id="email"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>
                  <br />

                  <div className="form-group">
                    <label>Passowrd</label>

                    <input
                      value={this.state.password}
                    
                      required
                      name="password"
                      id="password"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>
                  <br />

 
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Create New AVBOB Client Account"
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(AvbobUsersNew);
