import React from 'react';
import {connect} from 'react-redux';
import Fetcher from '../../actions/Fetcher';

import Messages from '../../components/Messages';
import {Link, NavLink} from 'react-router-dom';
// import { browserHistory } from "react-router";

import common from '../../components/common';
import DataTable from 'react-data-table-component';
import axios from 'axios';

class ProductsAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      page: 1,
      perPage: 10,
      data: [],
      loading: true,
      pagination: {},
    };
  }

  columns = [
    {name: 'Id', sortable: false, width: '100px', selector: row => row.id},
    {
      sortable:false ,
      name:"Image",
      width:'80px',
      selector : row=>row.image,
      cell : row => <img src={common.bs + '/..'+row.image} className='product-imga' />
    },
    {
      sortable: false,
      name: 'Name',
      selector: row => row.name,
    },
    {
      sortable: false,
      name: 'Category',
      selector: row => row.category_id,
      cell: u => this.state.categories['cat_'+u.category_id]
    },
    {
      name: 'Status',
      // sortable: true,
      cell: u =>
        parseInt(u.status) === 0 ? (
          <span className="badge badge-danger">Disabled</span>
        ) : parseInt(u.status) === 1 ? (
          <span className="badge badge-warning">Active</span>
        ):false
    },
    {
      allowOverflow: true,
      // selector: "actions",
      name: 'Actions',
      cell: u => (
        <div className="button">
          <NavLink to={'/product-edit/'+u.id} className='btn btn-xs btn-primary'>Edit</NavLink>
          {parseInt(u.status) === 1 ? (
            <button
              onClick={() => {
                this.updateItem(u.id, {status: 0});
              }}
              className="btn btn-danger btn-xs"
              type="button">
              Disable
            </button>
          ) : (
            <button
              onClick={() => {
                this.updateItem(u.id, {status: 1});
              }}
              className="btn btn-success btn-xs"
              type="button">
              Enable
            </button>
          )}
        </div>
      ),
    },
  ];

  updateItem(id, data) {
    Fetcher.post(common.bs + '/admin_products.php?action=updateoriginal', {
      id: id,
      data: data,
      token: this.props.token,
    })
      .then(resp => {
        this.setState({
          ['delete_' + id]: false,
        });
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Updated Successfully.'}],
          });

          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        console.log(err);
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
      });
  }

  async componentDidMount() {
    // this.handlePageChange();
    const {page} = this.state;

    this.setState({ loading: true });

    const response = await axios.get(
      `${common.bs}/admin_products.php?mode=didMount&action=list&page=${page}&per_page=10&delay=1&token=${this.props.token}`
    );

    const response2 = await axios.get(
      `${common.bs}/admin_categories.php?mode=didMount&action=list&page=1&per_page=10000&delay=1&token=${this.props.token}`
    );
    let cats = {}
      
    response2?.data?.data?.map((item, i)=>{
      cats['cat_'+item.id] = item.name;
    });
    this.setState({
      items: response.data.data,
      categories: cats,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  }

  // handleSort = async (a, b) => {
  //   this.setState(
  //     {
  //       sort: a.selector,
  //       sort_order: b,
  //     },
  //     () => {
  //       this.handlePageChange(1);
  //     }
  //   );
  // };

  handleOnChangeRowsPerPage = async page => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.handlePageChange(1);
      },
    );
  };

  handlePageChange = async page => {
    const {perPage} = this.state;

    this.setState({loading: true});
    let sort = '';
    // if (this.state.sort) {
    //   sort = "&sort=" + this.state.sort + "&order=" + this.state.sort_order;
    // }
    // if (this.state.do_filter) {
    //   sort += "&do_filter=" + this.state.do_filter + "&is_customer=y";
    // }
    // if (this.state.filter_extra && this.state.filter_extra != "all") {
    //   sort += "&filter_" + this.state.filter_extra;
    // }
    const response = await axios.get(
      `${common.bs}/admin_products.php?m=handlePageChange&action=list&page=${page}&per_page=${perPage}&delay=1${sort}&token=${this.props.token}`,
    );

    this.setState({
      items: response.data.data,
      page: page,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  };

  render() {
    const {totalRows, loading} = this.state;

    // console.log(this.state);

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <Messages messages={this.props.messages} />
            <DataTable
              title={'Products - All'}
              columns={this.columns}
              data={this.state.items}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 75, 100, 200, 500]}
              highlightOnHover
              fixedHeader
              noContextMenu
              fixedHeaderScrollHeight={'70vh'}
              pagination
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
              onSort={this.handleSort}
              sortServer
              actions={[
                <Link className="btn btn-primary" to="/product-new" onClick={()=>{
                  this.props.dispatch({
                    type: 'CLEAR_MESSAGES'
                  });
                }}>
                  New Product
                </Link>
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(ProductsAll);
