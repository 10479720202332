import React from 'react';
import {connect} from 'react-redux';
import Fetcher from '../../actions/Fetcher';

import Messages from '../../components/Messages';
import {Link} from 'react-router-dom';
// import { browserHistory } from "react-router";

import common from '../../components/common';
import DataTable from 'react-data-table-component';
import axios from 'axios';

class AppUsersAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      page: 1,
      perPage: 10,
      data: [],
      only_deleted:false,
      loading: true,
      pagination: {},
    };
  }

  columns = [
    {name: 'Id', sortable: false, width: '100px', selector: row => row.id},
    {
      sortable: false,
      name: 'First Name',
      selector: row => row.first_name,
    },    {
      sortable: false,
      name: 'Last Name',
      selector: row => row.last_name,
    },   {
      sortable: false,
      name: 'Email',
      selector: row => row.email,
    },  {
      sortable: false,
      name: 'Phone Number',
      selector: row => row.phone_number,
    },
    {name: 'Policy Number', selector: row => row.policy_number},

    {
      name: 'Status',
      // sortable: true,
      cell: u =>
        parseInt(u.status) === 2 ? (
          <span className="badge badge-danger">Disabled</span>
        ) : parseInt(u.status) === 1 ? (
          <span className="badge badge-warning">Active</span>
        ) : (
          <span className="badge badge-warning">Pending Confirmation</span>
        ),
    },

     
    {
      allowOverflow: true,
      // selector: "actions",
      name: 'Actions',
      cell: u => (
        <div className="button">
          {parseInt(u.status) === 1 ? (
            <>
            
            <Link to={'/app-user/'+u.id} 
              className="btn btn-info btn-xs"
              type="button">
              Modify
              </Link>
            <button
              onClick={() => {
                if(window.confirm("Are you sure you want to disable this Client? You will not be able to undo this action and all associated orders will be marked as cancelled."))
                {
                  this.deleteUser(u.id, {status: 2});
                }
                
              }}
              className="btn btn-danger btn-xs"
              type="button">
              Disable
            </button>
            </>
          ) : (
           false
          )}
        </div>
      ),
    },
  ];

  deleteUser(id, data) {
    Fetcher.post(common.bs + '/admin_appusers.php?action=deleteUser', {
      id: id,
      data: data,
      token: this.props.token,
    })
      .then(resp => {
        this.setState({
          ['delete_' + id]: false,
        });
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Disabled Successfully.'}],
          });

          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        console.log(err);
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
      });
  }

  updateItem(id, data) {
    Fetcher.post(common.bs + '/admin_appusers.php?action=update', {
      id: id,
      data: data,
      token: this.props.token,
    })
      .then(resp => {
        this.setState({
          ['delete_' + id]: false,
        });
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Update Successfully.'}],
          });

          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        console.log(err);
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
      });
  }

  async componentDidMount() {
    // this.handlePageChange();
    const {page} = this.state;

    this.setState({ loading: true });

    const response = await axios.get(
      `${common.bs}/admin_appusers.php?mode=didMount&action=list&filter_deleted=no&page=${page}&per_page=10&delay=1&token=${this.props.token}`
    );

    // console.log(response.data)
    this.setState({
      items: response.data.data,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  }

  // handleSort = async (a, b) => {
  //   this.setState(
  //     {
  //       sort: a.selector,
  //       sort_order: b,
  //     },
  //     () => {
  //       this.handlePageChange(1);
  //     }
  //   );
  // };

  handleOnChangeRowsPerPage = async page => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.handlePageChange(1);
      },
    );
  };

  switchDeleted(){
    this.setState({
      only_deleted:!this.state.only_deleted,
      perPage : 1
    }, ()=>{
      this.handlePageChange(1);
    })
  }

  handlePageChange = async page => {
    const {perPage} = this.state;

    this.setState({loading: true});
    let sort = '';
    // if (this.state.sort) {
    //   sort = "&sort=" + this.state.sort + "&order=" + this.state.sort_order;
    // }
    // if (this.state.do_filter) {
    //   sort += "&do_filter=" + this.state.do_filter + "&is_customer=y";
    // }
    // if (this.state.filter_extra && this.state.filter_extra != "all") {
    //   sort += "&filter_" + this.state.filter_extra;
    // }
    let show_del = 'no';
    if(this.state.only_deleted)
    {
      show_del =  'yes';
    }
    const response = await axios.get(
      `${common.bs}/admin_appusers.php?m=handlePageChange&action=list&filter_deleted=${show_del}&page=${page}&per_page=${perPage}&delay=1${sort}&token=${this.props.token}`,
    );

    this.setState({
      items: response.data.data,
      page: page,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  };

  render() {
    const {totalRows, loading} = this.state;

    // console.log(this.state);

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <Messages messages={this.props.messages} />
            <div style={{textAlign:"right"}}>
              {
                this.state.only_deleted ?
                <button onClick={this.switchDeleted.bind(this)} className='btn btn-sm btn-info'>Show Active Clients</button> :
                <button onClick={this.switchDeleted.bind(this)} className='btn btn-sm btn-warning'>Show Deleted Clients</button>
              }
            </div>
            <DataTable
              title={'Clients - All'}
              columns={this.columns}
              data={this.state.items}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 75, 100, 200, 500]}
              highlightOnHover
              fixedHeader
              noContextMenu
              fixedHeaderScrollHeight={'70vh'}
              pagination
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
              onSort={this.handleSort}
              sortServer
              actions={[
                // <Link className="btn btn-primary" to="/new-jobcard">
                //   New Job Card
                // </Link>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(AppUsersAll);
