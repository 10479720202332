import {Provider} from 'react-redux';
import configureStore from './store/configureStore';
import {BrowserRouter, Route, Navigate, Routes} from 'react-router-dom';
import App from './components/App';
import Login from './screens/auth/Login';
import Forgot from './screens/auth/Forgot';

import NotFound from './screens/NotFound';
import Profile from './screens/auth/Profile';

import JobCardsMy from './screens/avbob/JobCardsMy';
import JobCardsNew from './screens/avbob/JobCardsNew';
import UsersMy from './screens/avbob/UsersMy';

import history from './history';
import JobCardsAll from './screens/admin/JobCardsAll';
import AppUsersAll from './screens/admin/AppUsersAll';
import AppUserEdit from './screens/admin/AppUserEdit';
import AvbobUsersAll from './screens/admin/AvbobUsersAll';
import ProductsAll from './screens/admin/ProductsAll';
import ProductNew from './screens/admin/ProductNew';
import ProductEdit from './screens/admin/ProductEdit';
import CategoriesAll from './screens/admin/CategoriesAll';
import CategoryNew from './screens/admin/CategoryNew';
import CategoryEdit from './screens/admin/CategoryEdit';

import AvbobUsersNew from './screens/admin/AvbobUsersNew';
import Orders from './screens/admin/Orders';
import SuppliersAll from './screens/admin/SuppliersAll';
import SupplierNew from './screens/admin/SupplierNew';
import OrderDetails from './screens/admin/OrderDetails';

import QuoteRequests from './screens/supplier/QuoteRequests';
import OrderDetailsForSupplier from './screens/supplier/OrderDetailsForSupplier';
import Inventory from './screens/supplier/Inventory';
import Notifications from './screens/Notifications';

let userInfo = window.localStorage.getItem('koni_userInfo');
if (userInfo) userInfo = JSON.parse(userInfo);
let userRole = window.localStorage.getItem('koni_userRole');
let userToken = window.localStorage.getItem('koni_userToken');

const store = configureStore({
  auth:
    userToken && userRole && userInfo
      ? {
          token: userToken,
          role: userRole,
          user: userInfo,
        }
      : {
          token: false,
          user: false,
          role: 'anon',
        },
}); 
const isAvbob = user =>
  user && user.id && user.role == 'avbob' ? true : false;
  const isAdmin = user =>
    user && user.id && user.role == 'admin' ? true : false;
    const isSupplier = user =>
      user && user.id && user.role == 'supplier' ? true : false;

function AppMain() {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              path="/"
              index
              element={
                isAvbob(store.getState().auth.user) ? (
                  <Navigate to="my-jobscards" />
                ) : isAdmin(store.getState().auth.user) ? ( 
                  <Navigate to="jobcards" />

                ): (
                  <Login />
                )
              }
            />

            {isAvbob(store.getState().auth.user) ? (
              <>
                <Route path="my-jobcards" element={<JobCardsMy />} />
                <Route path="new-jobcard" element={<JobCardsNew />} />
                <Route path="my-users" element={<UsersMy />} />
              </>
            ) : isAdmin(store.getState().auth.user) ? (
              <>
                <Route path="jobcards" element={<JobCardsAll />} />
                <Route path="app-users" element={<AppUsersAll />} />
                <Route path="app-user/:id" element={<AppUserEdit />} />
                
                <Route path="avbob-users" element={<AvbobUsersAll />} />
                <Route path="products" element={<ProductsAll />} />
                <Route path="product-new" element={<ProductNew />} />
                <Route path="product-edit/:id" element={<ProductEdit />} />
                <Route path="categories" element={<CategoriesAll />} />
                <Route path="category-new" element={<CategoryNew />} />
                <Route path="category-edit/:id" element={<CategoryEdit />} />

                <Route path="new-avbob-client" element={<AvbobUsersNew />} />
                <Route path="orders" element={<Orders />} />
                <Route path="new-supplier" element={<SupplierNew />} />
                <Route path="suppliers" element={<SuppliersAll />} />
                <Route path="order/details/:orderId" element={<OrderDetails />} />
              </>
            ) : isSupplier(store.getState().auth.user) ? (
              <>
                <Route path="quote-requests" element={<QuoteRequests />} />
                <Route path="inventory" element={<Inventory />} />
                {/* <Route path="accepted-orders" element={<SuppliersAll />} /> */}
                <Route path="quote/orderdetails/:orderId" element={<OrderDetailsForSupplier />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot" element={<Forgot />} />
              </>
              
            )}
              
              
              <Route
              path="notifications"
              element={
                store.getState().auth.user && store.getState().auth.user.id ? (
                  <Notifications />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
              <Route
              path="my-account"
              element={
                store.getState().auth.user && store.getState().auth.user.id ? (
                  <Profile />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            {/* suppliers */}
            {/* new-avbob-client */}
            {/* orders */}
            {/* 
            <Route
              path="*"
              element={
                store.getState().auth.user && store.getState().auth.user.id ? (
                  <Navigate to="/my-jobcards" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default AppMain;
