import React from 'react';
// import { IndexLink, Link } from "react-router";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
// import { logout } from "../actions/auth";
// import moment from "moment";

class Header extends React.Component {
  state = {links: []};

  componentDidMount() {
    let links = [];
    if (!this.props.user) return;

    if (this.props.user.role === 'avbob') {
      links.push({
        icon: 'fa fa-chart-bar',
        name: 'My Job Cards',
        path: '/my-jobcards',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'My Clients',
        path: '/my-users',
      });
    } else if (this.props.user.role === 'admin') {
      links.push({
        icon: 'fa fa-chart-bar',
        name: 'All Job Cards',
        path: '/jobcards',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Clients',
        path: '/app-users',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Avbob Clients',
        path: '/avbob-users',
      });
      links.push({
        icon: 'fa fa-boxes',
        name: 'Categories',
        path: '/categories',
      });links.push({
        icon: 'fa fa-boxes',
        name: 'Products',
        path: '/products',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Suppliers',
        path: '/suppliers',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Orders',
        path: '/orders',
      });
    } else if ( this.props.user.role == 'supplier' )
    {
      links.push({
        icon: 'fa fa-chart-bar',
        name:"Quote Requests",
        path: "/quote-requests"
      });
      links.push({
        icon: 'fa fa-warehouse-alt',
        name: 'Inventory',
        path: '/inventory',
      });
      // links.push({
      //   icon: 'fa fa-chart-bar',
      //   name:"Accepted Orders",
      //   path: "/accepted-orders"
      // });
    }
  

    links.push({
      icon: 'fa fa-user',
      name: 'Notifications',
      path: '/notifications',
    });
    links.push({
      icon: 'fa fa-user',
      name: 'Account Info',
      path: '/my-account',
    });
    this.setState({
      links: links,
    });
  }
  render() {
    const active = {borderBottomColor: '#3f51b5'};
    return (
      <nav className="navbar navbar-expand-lg  navbar-light flex-md-column flex-row align-items-stretch">
        {/* <a className="d-xl-none d-lg-none" href="#">
          NHVR Electronic Work Diary
        </a> */}
        <button
          className="navbar-toggler order-md-1 order-2"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <img src="/assets/images/logo.png" style={{maxWidth:200}} alt="logo" className="img-flui mt-0 order-1 order-md-1" /> */}

        <div
          className="collapse navbar-collapse pt-3 mt-3 order-md-3 order-3"
          id="navbarNav">
          {this.props.token ? (
            <ul className="navbar-nav flex-column ">
              {/* <li className="nav-item">
                <IndexLink to="/" activeStyle={active} className="nav-link">
                  <span className="fa fa-home"></span>{' '}
                  Home
                </IndexLink>
              </li> */}
              {this.state.links.map((item, i) => {
                return (
                  <li className="nav-item" key={item.name}>
                    {item.is_title ? (
                      <span
                        style={{
                          marginTop: '30px',
                          fontWeight: 'bold',
                          display: 'inline-block',
                          padding: '12px',
                          color: '#000',
                          letterSpacing: '1px',
                        }}>
                        {item.name}
                      </span>
                    ) : item.path == '#' && item.items ? (
                      <a
                        href="#"
                        data-toggle="collapse"
                        className=" nav-link"
                        data-target={'#' + item.id}
                        aria-controls={item.id}>
                        {item.icon ? (
                          <span className={item.icon}></span>
                        ) : (
                          false
                        )}{' '}
                        {item.name} <i className="caret" />{' '}
                        {item.is_new ? (
                          <span className="badge badge-danger">New</span>
                        ) : (
                          false
                        )}
                      </a>
                    ) : (
                      <Link className="nav-link" to={item.path} onClick={()=>{
                        this.props.dispatch({
                          type: 'CLEAR_MESSAGES'
                        });
                      }}>
                        {item.icon ? (
                          <span className={item.icon}></span>
                        ) : (
                          false
                        )}{' '}
                        {item.name}
                      </Link>
                    )}
                    {item.items ? (
                      <div id={item.id} className="collapse submenu">
                        <ul className="nav flex-column">
                          {item.items.map((sub, i) => {
                            if (sub.is_label) {
                              return (
                                <li key={sub.name}>&nbsp;&nbsp;{sub.name}</li>
                              );
                            }
                            return (
                              <li key={sub.name} className="nav-item">
                                <Link
                      
                                  className="nav-link"
                                  to={sub.is_force ? '/downloading' : sub.path}
                                  onClick={() => {
                                    this.props.dispatch({
                                      type: 'CLEAR_MESSAGES'
                                    });
                                    if (sub.is_force) {
                                      window.location.href = sub.path;
                                      return false;
                                    }
                                  }}
                                  activeStyle={active}>
                                  {sub.name}{' '}
                                  {sub.is_new ? (
                                    <span className="badge badge-danger">
                                      New
                                    </span>
                                  ) : (
                                    false
                                  )}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      false
                    )}
                  </li>
                );
              })}
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (document.body.classList.contains('collapsedmenu'))
                      document.body.classList.remove('collapsedmenu');
                    else document.body.classList.add('collapsedmenu');
                  }}>
                  <span className={'fa fa-angle-double-left'}></span> {'<<'}
                </a>
              </li>
            </ul>
          ) : (
            false
          )}
          {/* {rightNav} */}
        </div>
        {/* </div> */}
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Header);
