import React from "react";
import { connect } from "react-redux";
import {
  updateProfile,
  changePassword,
  deleteAccount,
} from "../../actions/auth";
import Messages from "../../components/Messages";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.user.email,
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      middle_name: props.user.middle_name,
      phone_number: props.user.phone_number,
      password: "",
      confirm: "",
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleProfileUpdate(event) {
    event.preventDefault();
    this.props.dispatch(updateProfile(this.props.user.role, {
      first_name:this.state.first_name,
      last_name:this.state.last_name,
    }, this.props.token));
  }

  handleChangePassword(event) {
    event.preventDefault();
    this.props.dispatch(
      changePassword(this.props.user.role, {
        password: this.state.password
      }, this.props.token)
    );
  }

  // handleDeleteAccount(event) {
  //   event.preventDefault();
  //   this.props.dispatch(deleteAccount(this.props.token));
  // }

  render() {
    let vehicles = this.props.user.vehicle_ids;
    if (!vehicles) vehicles = "[]";
    vehicles = JSON.parse(vehicles);
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <Messages messages={this.props.messages} />
                <form
                  onSubmit={this.handleProfileUpdate.bind(this)}
                  className="form-horizontal"
                >
                  <legend>Profile Information</legend>

                  <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <div>
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        className="form-control"
                        value={this.state.first_name}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <div>
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        className="form-control"
                        value={this.state.last_name}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        readOnly
                        disabled
                        className="form-control"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                  {/* 
              <div className="form-group">
                <label htmlFor="phone_number">
                  Phone Number
                </label>
                <div >
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    className="form-control"
                    value={this.state.phone_number}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div> */}

                  <div className="form-group">
                    <div className="col-sm-offset-3 col-sm-4">
                      <button
                        type="submit"
                        className="btn btn-success"
                        // disabled
                      >
                        Update Profile
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <form
                  onSubmit={this.handleChangePassword.bind(this)}
                  className="form-horizontal"
                >
                  <legend>Change Password</legend>
                  <div className="form-group">
                    <label htmlFor="password">New Password</label>
                    <div>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirm">Confirm Password</label>
                    <div>
                      <input
                        type="password"
                        name="confirm"
                        id="confirm"
                        className="form-control"
                        value={this.state.confirm}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-4 col-sm-offset-3">
                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={!this.state.password || (this.state.confirm != this.state.password )}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(Profile);
