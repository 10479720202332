import React from 'react';
import {connect} from 'react-redux';
import {forgotPassword} from '../../actions/auth';
import Messages from '../../components/Messages';
import { Link } from "react-router-dom"; 
class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {email: '', loginAs:"avbob"};
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleForgot(event) {
    event.preventDefault();
    if(this.setState.please_wait){return false;}
    this.setState({
      please_wait : true
    })
    this.props.dispatch(forgotPassword(this.state.email, this.state.loginAs, ()=>{
      this.setState({
        please_wait: false
      })
    }));
  }

  render() {
    return (

      <div className="container-fluid dashboard-content">
                  <div className="row">         
                             <div className="col-md-4 offset-md-4">

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header">
                                <h2 className="pageheader-title">Forgot Password </h2>
                            </div>
                        </div>
                    </div>

                    
                    
            <Messages messages={this.props.messages} />
            <form onSubmit={this.handleForgot.bind(this)}>
              {/* <legend>Forgot Password</legend> */}
              <div className="form-group">
                <p>
                  Enter your email address below and we'll send you password
                  reset instructions.
                </p>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="form-control"
                  autoFocus
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                />
              </div>

              <div className="form-group">
              <label htmlFor="password">Select Role</label>
                <select className='form-control'  id="loginAs" 
                  onChange={this.handleChange.bind(this)} value={this.state.loginAs} required name="loginAs">
                  <option value="">Choose</option>
                  <option value={'avbob'}>AVBOB</option>
                  <option value={'admin'}>Nkonkoni</option>
                  <option value={'supplier'}>Supplier</option>
        </select>
        </div>

        

              <button type="submit" disabled={this.state.please_wait} className="btn btn-success">
                {this.state.please_wait  ?'Please wait...' : 'Reset Password'}
              </button>
              <div className="form-group" style={{float:'right', marginTop:10}}>
        <Link to="/login">
                  <strong>Go back to Login</strong>
                </Link>
              </div> 
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: state.messages
  };
};

export default connect(mapStateToProps)(Forgot);
