import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import Fetcher from "../../actions/Fetcher";
import Messages from "../../components/Messages";
import AssignSuppliers from "../../components/AssignSuppliers";
import common from "../../components/common";
import axios from "axios";
import { useParams } from "react-router-dom";
import Progress from '../../components/Progress';
import utils from '../../utils';
const {
  getFancyStatus,
  findSupplier,
  getStatusColor,
  getOrderDataAr,
  getFancyQuoteStatus,
  getOrderData
} = utils;
let baseOrderUrl = `${common.bs}/supplier_actions.php`;




const Box = ({ lbl, val, img, id, onChange }) => {
    const [qty, setQty] = useState(val);
  return (
    <div className="flex-row">
        <div>
            {
                img && <img src={common.bs+'/..'+img} className="product-imgass" /> 
            }
        

      <span>{lbl}</span>
      </div>
      <form onSubmit={e=>{
        e.preventDefault();
        onChange(id, qty)
      }} className="qtyboxparent">
        
      <input type='number' value={qty||val} onChange={(e)=>{
        setQty(e.target.value);
      }} className="form-control qtybox" />
      {
        val!=qty ? <button type='submit' className="btn btn-xs btn-primary" onClick={()=>{
       
        }}>Save</button> : false
      }
      </form>
    </div>
  );
};

const InventoryList = (props) => {
 
  const [p, setP] = useState(0);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [inventory, setInventory] = useState('');
  const [inventoryChange, setInventoryChanges] = useState('');
  const params = useParams();

  useEffect(() => {
    async function getDetails() {
      const resp = await axios.get(
        `${common.bs}/supplier_actions.php?action=getInventoryData&token=${props.token}`
      );
      setInventory(resp?.data?.inventory);
      setProducts(resp?.data?.products)
      setCategories(resp?.data?.categories)
    }
    getDetails();
  }, [p]);

  useEffect(() => {
    setP(1);
  });


  
  const handleSave = async (id, qty)=>
  {
 
    let url = `${common.bs}/supplier_actions.php?action=saveInventorySingle&token=${props.token}`
    let resp  = await axios.post(url, {
      qty : qty,
      product_id: id,
      token : props.token,
    });
    if(resp.data.ok)
    {
        alert('Saved!')
      setP(p + 1);
    }
  }
  

  // console.log(avbob);
  return (
    <div className="container-fluid dashboard-content">
      <h2>Inventory Details</h2>
      <Messages messages={props.messages} />
      <div className="row">
        {
            categories && categories.map(category=>{
                return (
                    <div className="col-md-4   " key={category.id} style={{marginBottom:30 }}>
                        <div className="bg-white p-3">
                            <div className="flex-box">
                                <h4>
                                    {
                                        category.image && <img src={common.bs+'/..'+category.image} className="product-imgas" />
                                    }
                                    <strong>{category.name}</strong>
                                </h4>
                            <hr />

                            {products && products.map((item, i) => {
                                if(item.category_id!=category.id)return false;
                                return <Box lbl={item.name} id={item.id} img={item.image} key={item.id} val={inventory['p_'+item.id] ? inventory['p_'+item.id] : 0 } onChange={(id, qty)=>{
                                    handleSave(id, qty)
                                }} />;
                            })}
                            </div>
                        </div>
                        </div>

                )
            })
        }
        
        
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(InventoryList);
