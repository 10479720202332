import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import Fetcher from "../../actions/Fetcher";
import Messages from "../../components/Messages";
import AssignSuppliers from "../../components/AssignSuppliers";
import Progress from '../../components/Progress';

import common from "../../components/common";
import axios from "axios";
import { useParams } from "react-router-dom";

import utils from '../../utils';
const {
  getFancyStatus,
  findSupplier,
  getStatusColor,
  getOrderDataAr,
  getFancyQuoteStatus,
  getOrderData
} = utils;
let baseOrderUrl = `${common.bs}/admin_orders.php`;


const lbls = {
  addr_name : "Name",
  addr_line_1: 'Address Line 1',
  addr_city: 'City',
  addr_state: 'State',
  addr_postal: 'Postal',
  

}


const Box = ({ lbl, val }) => {
  return (
    <div className="flex-row">
      <strong>{lbl}</strong>
      <span style={{ textAlign: "right" }}>{val}</span>
    </div>
  );
};

const OrderDetails = (props) => {
  const [p, setP] = useState(0);
  const [order, setOrder] = useState({});
  const [user, setUser] = useState({});
  const [ processing, setProcessing] = useState(false);
  const [ categories, setCategories] = useState({});
  const [assigned, setAssigned] = useState([]);
  const [jobcard, setJobCard] = useState({});
  const [selected_quote, setSelectedQuote] = useState(false);
  const [avbob, setAvbob] = useState({});
  const [suppliers, setSuppliers] = useState({});

  const [payment_link, setPaymentLink] = useState('');
  const [banking_details, setBankingDetails] = useState('');

  const params = useParams();
  // console.log(params);

  useEffect(() => {
    async function getDetails() {
      setProcessing(true);
      setSelectedQuote(false);
      const resp = await axios.get(
        `${common.bs}/admin_orders.php?action=details&id=${params.orderId}&token=${props.token}`
      );
      setOrder(resp.data.order);
      setAvbob(resp.data.avbob);
      setJobCard(resp.data.jobcard);
      // setCategory(resp.data.category);
      setUser(resp.data.user);
      setSuppliers(resp.data.suppliers);
      setAssigned(resp.data.assigned);

      const resp2 = await axios.get(
        `${common.bs}/admin_categories.php?action=list&per_page=1&page=1&id=${params.orderId}&token=${props.token}`
      );
      let categories = resp2?.data?.data || [];
      let catObj = {}
      categories.map(item=>{
        catObj['c_'+item.id] = item.name;
      })
      setCategories(catObj);

      
      setProcessing(false);
    }
    getDetails();
  }, [p]);

  useEffect(() => {
    setP(1);
  });

  const orderData = order.id ? getOrderData(order.order_data) : {};
  const orderDataAr = order.id ? getOrderDataAr(orderData) : [];
  
  const addressData = order.id ? getOrderData(order.address_data) : {};
  const addressDataAr = order.id ? getOrderDataAr(addressData) : [];

  const rejectQuote = async (id)=>
  {
    setProcessing(true);
    let resp  = await axios.get(baseOrderUrl+'?action='+'rejectQuote'+'&quoteId='+id+'&token='+props.token);
    if(resp.data.ok)
    {
      setP(p + 1);
    }
  }
  const sentToCustomer = async (e)=>
  {
    e.preventDefault();
    setProcessing(true);
    let resp  = await axios.post(baseOrderUrl+'?action='+'sentToCustomer'+'&token='+props.token, {
      quoteId: selected_quote.id,
      admin_amount: selected_quote.admin_amount,
      admin_quote: selected_quote.admin_quote,
    });
    if(resp.data.ok)
    {
      setP(p + 1);
    }
  }

  const markOrderComplete = async () =>{
    // e.preventDefault();
    setProcessing(true);
    let resp  = await axios.get(baseOrderUrl+'?action='+'completeOrder'+'&order='+order.id+'&token='+props.token);
    if(resp.data.ok)
    {
      setP(p + 1);
    }
  }

  const sendPaymentLink = async e =>{
    e.preventDefault();
    setProcessing(true);
    let resp = await axios.post(baseOrderUrl +'?action=paymentlinkupdated&token='+props.token, {
      orderId : order.id,
      payment_link: payment_link || '',
      banking_details : banking_details || ''
    })
    if(resp.data.ok)
    {
      setP(p+1);
    }
  }

  // console.log(avbob);
  return (
    <div className="container-fluid dashboard-content">
                <Progress showing={processing} />

      <h2>{"Order details - #" + params.orderId}</h2>
      <Messages messages={props.messages} />
      <div className="row">
        <div className="col-md-4  ">
          <div className="bg-white p-3">
            <div className="flex-box">
              <span style={{ textAlign: "right", float: "right" }}>
                <span className={"badge badge-" + getStatusColor(order.status)}>
                  {getFancyStatus(order.status)}
                </span>
              </span>
              <h4>Order Details</h4>

              <hr />
              <img src={common.bs + '/..'+orderData.image}  style={{width:'100%'}}/>
              <h3>{orderData.name}</h3>
{/* 
              {orderDataAr.map((item, i) => {
                return <Box key={item.key} lbl={lbls[item.key] ? lbls[item.key] : item.key} val={item.val} />;
              })} */}
            </div>
          </div>
        </div>

        <div className="col-md-4 ">
          <div className="bg-white p-3 ">
            <div className="flex-box">
              <h4>Client Details</h4>
              <hr />
              <Box lbl={"Client ID"} val={user.id} />
              <Box lbl={"Name"} val={user.first_name + " " + user.last_name} />
              <Box lbl={"Phone Number"} val={user.phone_number} />
              <Box lbl={"Email"} val={user.email} />
              {/* <Box lbl={"Policy Number"} val={user.policy_number} /> */}
            </div>
          </div>
        </div>

        <div className="col-md-4 ">
          <div className="bg-white p-3 ">
            <div className="flex-box">
              <h4>Address Details</h4>
              <hr />

              {addressDataAr.map((item, i) => {
                return <Box key={item.key} lbl={lbls[item.key] ? lbls[item.key] : item.key} val={item.val} />;
              })}
              
            </div>
          </div>
        </div>

 
      </div>

      <div className="row" style={{marginTop:40}}>
        <div className="col-md-4  ">
          <div className="bg-white p-3">
            <h4>{order.status == "new" ? "Assign" : "Assigned"} Suppliers</h4>
            <hr />

            {order.status == "new" ? (
              <AssignSuppliers
              onStart={()=>{
                setProcessing(true);
              }}
              categories={categories}
                onSelected={() => {
                  setP(p + 1);
                  // props.dis
                }}
                order={order}
                suppliers={suppliers}
                token={props.token}
              />
            ) : (
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Quote Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assigned.map((item, i) => {
                      let sup = findSupplier(item.supplier_id, suppliers);
                      return (
                        <tr key={item.id}>
                          <td>{item.supplier_id}</td>
                          <td>
                            {sup.first_name} {sup.last_name}
                          </td>
                          <td>{sup.email}</td>
                          <td>
                            {getFancyQuoteStatus(item.status)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        {order.status == "new" ? (
          false
        ) : (
          <div className="col-md-8  ">
            <div className="bg-white p-3">
              <h4>{order.status == "new" ? "Assign" : "Assigned"} Suppliers</h4>
              <hr />
              <table className="table">
                <thead>
                  <tr>
                    <th>Quote Id</th>
                    <th>Supplier</th>
                    <th>Quotation</th>
                    <th>Amount</th>
                    <th>Provides Delivery</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              <tbody>
              {
                assigned.map((item, i)=>{
                  if(item.status == 'assigned')return false;
                  let sup = findSupplier(item.supplier_id, suppliers);
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{sup.first_name} {sup.last_name}(#{sup.id})</td>
                      <td>{
                      item.admin_quote ?
                        <>
                          <strike>{item.quote}</strike> {item.admin_quote}
                        </> : item.quote
                      }</td>

                      <td>R {
                      item.admin_amount ?
                        <>
                          <strike>{parseFloat(item.amount).toFixed(2)}</strike> {parseFloat(item.admin_amount).toFixed(2)}
                        </> : parseFloat(item.amount).toFixed(2)
                      }</td>
                   

                      <td>{item.provides_delivery}</td>
                      <td>
                        {
                          selected_quote ? false :
                          item.status == 'quoted' ? <>
                            <button disabled={order.status == 'cancelled' ? true : false} onClick={()=>{
                              
                              setSelectedQuote(item)
                              // return;if(window.confirm('Are you sure you want to send this quote to the user?')){
                              // sentToCustomer(item.id);
                            // }
                            }}  className="btn-sm btn btn-primary">Send to User</button>{' '}
                            <button disabled={order.status == 'cancelled' ? true : false}  onClick={()=>{if(window.confirm('Are you sure you want to reject this quote?')){
                              rejectQuote(item.id);
                            }}} className="btn-sm btn btn-danger">Reject</button>
                          </>:(<span>No action required.</span>)
                        }
                        
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
              </table>
            </div>

            {selected_quote ? <br /> : false}

            {
              selected_quote ?

              <div className="bg-white p-3">
                <h4>Modify Quote Before Sending</h4>
                <hr />
                <form className="" onSubmit={sentToCustomer}>
                <div className="form-group">
                    <label>Quote Amount</label>
                    <input type="number" step="0.1" min="0.1"  value={selected_quote.admin_amount || selected_quote.amount} onChange={( e)=>{
                      let amnt = e.target.value;
                      let sq = JSON.parse(JSON.stringify(selected_quote));
                      sq.admin_amount = amnt;
                      setSelectedQuote(sq);
                    }} id="amount" name="amount" className="form-control" />
                  </div>
                  
                  
                  <div className="form-group">
                  <label>Quote Remarks</label>
                      <textarea value={selected_quote.admin_quote || selected_quote.quote} onChange={( e)=>{
                      let amnt = e.target.value;
                      let sq = JSON.parse(JSON.stringify(selected_quote));
                      sq.admin_quote = amnt;
                      setSelectedQuote(sq);
                    }}  id="quote" name="quote" className="form-control" ></textarea>
                    </div>
                    <p>
                      <button type="submit" className="btn btn-success">Submit Quotation</button>{" "}
                      <button type="button" onClick={e=>{
                        e.preventDefault();
                        setSelectedQuote(false)
                        
                      }} className="btn btn-secondary">Cancel</button>
                    </p>
                    </form>
                    </div> : false

            }
          </div>
        )}
      </div>

{
  order.status == 'quote_accepted' ||
  order.status == 'payment_pending' ||
  order.status == 'payment_done' ||
  order.status == 'complete' ?
      <div className="row" style={{marginTop:40}}>
        <div className="col-md-4">
          <div className="bg-white p-3">
            <h4>{
               order.status == 'quote_accepted' ?  'Send Payment Link' : 'Payment Link'}</h4>
            <hr />
            <div className="">
                
              {
                  order.status == 'quote_accepted' ? 
                  <form className="" onSubmit={sendPaymentLink}>
                    <div className="form-group">
                      <label>Payment Link</label>
                      <input required={banking_details ? false : true } type="url" className="form-control" name="payment_link" value={payment_link} onChange={e=>{
                        setPaymentLink(e.target.value);
                      }} />
                    </div>
                    <div className="form-group">
                      <label>Banking Details</label>
                      <input required={payment_link ? false : true } type="text" className="form-control" name="banking_details" value={banking_details} onChange={e=>{
                        setBankingDetails(e.target.value);
                      }} />
                    </div>
                    <p>
                      <button type="submit" className="btn btn-primary">Send Payment Details</button>
                    </p>
                  </form> : <>
                  {
                    order.payment_link ? 
                    <div>
                      <strong>Payment Link:</strong>
                      <br />
                      {order.payment_link}
                    </div> 
                    : false
                  }
                  {
                    order.banking_details ? 
                    <div>
                      <br />
                      <strong>Banking Details:</strong>
                      <br />
                      {order.banking_details}
                    </div> 
                    : false
                  }
                  
                  </>
              }
            </div>
          </div>
        </div>
        {
          order.status == 'payment_done'? 
          <div className="col-md-4">
            <div className="bg-white p-3">
              <button className="btn btn-success" type="button" onClick={e=>{
                if(window.confirm('Are you sure you want to mark the order as complete?'))
                {
                  markOrderComplete();
                }
              }}>Mark the order as complete</button>
            </div>
          </div>:false
        }
      </div>
      : false }
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(OrderDetails);
