import React from 'react';
import {connect} from 'react-redux';
// import Fetcher from "../../actions/Fetcher";
import Messages from '../../components/Messages';
import common from '../../components/common';
import axios from 'axios';

class JobCardNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jobcard_number: '',
      policy_number: '',
      location: '',
      remarks: '',
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async createJobCard(e) {
    e.preventDefault();
    let {jobcard_number, policy_number, remarks, location} = this.state;
    let obj = {jobcard_number, policy_number, remarks, location};
    let send_data = {
      data: obj,
    };

    let url = `${common.bs}/avbob_jobcards.php?action=create&token=${this.props.token}`;
    const response = await axios.post(url, send_data);
    let data = response.data;
    if (data.ok) {
      this.setState({
        jobcard_number: '',
        location: '',
        policy_number: '',
        remarks: '',
      });
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: 'Job Card Created successfully.'}],
      });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to create Job Card'}],
      });
    }

    // if(response)
  }

  render() {
    // const {totalRows, loading} = this.state;

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h2>{'New Job Card'}</h2>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.createJobCard.bind(this)}>
              <h3>Job Card Details</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Job Card Number</label>
                    {/* df */}
                    <input
                      value={this.state.jobcard_number}
                      type="text"
                      required
                      name="jobcard_number"
                      id="jobcard_number"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                  {/* </div>
                </div> */}
                  <br />
                  {/* <div className="row"> */}
                  {/* <div className="col-md-12"> */}
                  <div className="form-group">
                    <label>Policy Number</label>

                    <input
                      value={this.state.policy_number}
                      type="number"
                      required
                      name="policy_number"
                      id="policy_number"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>
                  <br />

                  <div className="form-group">
                    <label>Remarks</label>

                    <textarea
                      value={this.state.remarks}
                      required
                      name="remarks"
                      id="remarks"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"></textarea>
                  </div>
                  <div className="form-group">
                    <label>Location</label>

                    <textarea
                      value={this.state.location}
                      required
                      name="location"
                      id="location"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"></textarea>
                  </div>
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Create Job Card"
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(JobCardNew);
