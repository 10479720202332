
import axios from "axios";
import React, { useEffect, useState } from "react";
import common from './common';
const AssignSuppliers = ({order, suppliers, categories, token, onSelected, onStart}) =>{
const[selectedList , setSelectedList] = useState([])

  const saveSupplierList = async ()=>{
    onStart();
    let url =`${common.bs}/admin_orders.php?action=assignSupplier&token=${token}`
    let resp  = await axios.post(url, {
      data : {
        suppliers : selectedList.join(','),
        orderId : order.id
      },
      token : token
    })
    if(resp.data.ok)
    {
      onSelected(resp.data);
    }
  }
  const getCategoryNames = function(cats, ){
    if(!cats) cats =[];
    else cats = cats.split(',');
    cats = cats.map((item,i)=>{
      if(categories['c_'+item])
      return categories['c_'+item]
      return '';
    })
    cats = cats.join(', ');
    return cats;

  }

  return (
    <div>
      <h4>Choose Suppliers</h4>
      {
        suppliers.map((item, i)=>{
          return(<div key={item.id}>
          <label className="input_lbl">
            <input type={'checkbox'} checked={selectedList.indexOf(''+item.id) > -1} onChange={()=>{
              
              let rmp = JSON.parse(JSON.stringify(selectedList));
              if(rmp.indexOf(item.id) > -1)
              {

                rmp.splice(rmp.indexOf(item.id), 1);
              }else{
                rmp.push(''+item.id);
              }
              setSelectedList(rmp);

            }} /> {item.first_name} {item.last_name} ( #{item.id} ) - {item.email} ( {item.location} )
            <br /><i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deals in: {getCategoryNames(item.categories)}</i><br /><br />
          </label></div>);
        })
      }
<br />
<hr />
      <button className="btn btn-primary" onClick={saveSupplierList}>Save Suppliers</button>
    </div>
  )
}

export default AssignSuppliers;