import React from 'react';
import {connect} from 'react-redux';
import Fetcher from '../../actions/Fetcher';

import Messages from '../../components/Messages';
import {Link} from 'react-router-dom';
import moment from 'moment';

import common from '../../components/common';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import utils from '../../utils';
const {
  getFancyStatus, getcategory
} = utils;


class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      page: 1,
      perPage: 10,
      data: [],
      loading: true,
      pagination: {},
    };
  }

  columns = [
    {name: 'Id', sortable: false, width: '100px', selector: row => row.id},
    {
      sortable: false,
      name: 'Client Name',
      selector: row => row.email,
      format : row=> ''+row.first_name+' '+row.last_name+' ('+row.user_id+')'
    }, 
    {
      sortable:false, 
      name:"Created on",
      selector: row => row.created_at,
      format: row => moment(row.created_at).format('lll')
    },
    {
      sortable:false, 
      name: "Category",
      selector: row => row.order_data,
      format: row => getcategory(row.order_data)
    },
    
    {
      name: 'Status',
      // sortable: true,
      selector : row => row.status,
      cell: row => getFancyStatus(row.status)
    },
    {
      allowOverflow: true,
      // selector: "actions",
      name: 'Actions',
      cell: u => (
        <div className="button">
          <Link to={"/order/details/"+u.id}
            className="btn btn-primary btn-xs">
            View Order
          </Link>
        </div>
      ),
    },
  ];

  updateItem(id, data) {
    Fetcher.post(common.bs + '/admin_avbobusers.php?action=update', {
      id: id,
      data: data,
      token: this.props.token,
    })
      .then(resp => {
        this.setState({
          ['delete_' + id]: false,
        });
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Update Successfully.'}],
          });

          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        console.log(err);
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
      });
  }

  async componentDidMount() {
    this.handlePageChange(1);

  }
  handleOnChangeRowsPerPage = async page => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.handlePageChange(1);
      },
    );
  };

  handlePageChange = async page => {
    const {perPage} = this.state;

    this.setState({loading: true});
    let sort = '';
    
    const response = await axios.get(
      `${common.bs}/admin_orders.php?m=handlePageChange&action=list&page=${page}&per_page=${perPage}&delay=1${sort}&token=${this.props.token}`,
    );

    this.setState({
      items: response.data.data,
      page: page,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  };

  render() {
    const {totalRows, loading} = this.state;

    // console.log(this.state);

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <Messages messages={this.props.messages} />
            <DataTable
              title={'Orders - All'}
              columns={this.columns}
              data={this.state.items}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 75, 100, 200, 500]}
              highlightOnHover
              fixedHeader
              noContextMenu
              fixedHeaderScrollHeight={'70vh'}
              pagination
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
              onSort={this.handleSort}
              sortServer
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(Orders);
