import React from 'react';
import {connect} from 'react-redux';
// import Fetcher from "../../actions/Fetcher";
import Messages from '../../components/Messages';
import common from '../../components/common';
import axios from 'axios';
import { useParams } from "react-router-dom";

class AppUserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      first_name:'', last_name:'', email:'', password:''
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  componentDidMount(){ 
    this.getUser();
  }

   
  async getUser() {
    const response = await axios.get(
      `${common.bs}/admin_appusers.php?mode=didMount&action=get_single&id=${this.props.id}&token=${this.props.token}`
    );
    this.setState({
    
      first_name: response?.data?.user?.first_name,
      last_name: response?.data?.user?.last_name,
      email: response?.data?.user?.email
 
    });
  }


  handleFileChange  (e){
    
    this.setState({
      files : e.target.files
    })
  };

  async createProduct2(e)
  {
    e.preventDefault();
    if(this.state.saving)return false
    this.setState({saving:true});
    let {password} = this.state;
    const filedata = new FormData();
    
    filedata.append('password', (password||'').trim())
    filedata.append('id', this.props.id) 
    
    let url = `${common.bs}/admin_appusers.php?action=update&token=${this.props.token}`;
    const response = await axios.post(url, filedata);
    let data = response.data;
    this.setState({
      saving:false
    })
    if (data.ok) {
     
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: data.msg || 'User updated successfully.'}],
      });
      this.getUser();

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to update User'}],
      });
    }

  }
  async createProduct(e) {
    e.preventDefault();
    if(this.state.saving)return false
    this.setState({saving:true});
    let {first_name, last_name, email} = this.state;
    const filedata = new FormData();
    
    filedata.append('first_name', (first_name || '').trim());
    filedata.append('last_name', (last_name || '').trim());
    filedata.append('email', (email || '').trim());
    filedata.append('id', this.props.id) 
    
    let url = `${common.bs}/admin_appusers.php?action=update&token=${this.props.token}`;
    const response = await axios.post(url, filedata);
    let data = response.data;
    this.setState({
      saving:false
    })
    if (data.ok) {
     
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: data.msg || 'Client updated successfully.'}],
      });
      this.getUser();

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to update Client'}],
      });
    }
  }

  render() {
    const {categories} = this.state;

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8   p-3">
          <div className=" bg-white  p-3">
            <h2>{'Edit User'}</h2>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.createProduct.bind(this)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      value={this.state.first_name}
                      type="text"
                      required
                      name="first_name"
                      id="first_name"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                  
                  <br />
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      value={this.state.last_name}
                      type="text"
                      required
                      name="last_name"
                      id="last_name"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                  
                  <br />
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      value={this.state.email}
                      type="text"
                      required
                      name="email"
                      id="email"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
          
 
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={this.state.saving ? 'please wait...' :"Update Client"}
                />
              </p>
            </form>
          </div>
          </div>
          <div className='col-md-4  p-3'>
          <div className=" bg-white  p-3">

            <h2>Update Password</h2>
            <form onSubmit={this.createProduct2.bind(this)}>
              <div className="row">
                <div className="col-md-12">
                 
                  
               

                 <br />
                  <div className="form-group">
                    <label>New Password </label>
                    <input
                      value={this.state.password}
                      type="password"
                       required
                      name="password"
                      id="password"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Confirm Password </label>
                    <input
                      value={this.state.cpassword}
                      type="password"
                      required
                       
                      name="cpassword"
                      id="cpassword"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                 
 
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-primary"
                  disabled={this.state.password!=this.state.cpassword}
                  value={this.state.saving ? 'please wait...' :"Update Client Password"}
                />
              </p>
            </form>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};


const AppUserEditParent = (props) => {
  
  const params = useParams();
 
  return <AppUserEdit {...props} id={params.id}  />
}

export default connect(mapStateToProps)(AppUserEditParent);
