import React from 'react';
import {connect} from 'react-redux';
// import Fetcher from "../../actions/Fetcher";
import Messages from '../../components/Messages';
import common from '../../components/common';
import axios from 'axios';

class CategoryNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
     
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  componentDidMount(){
   
  }

   

  handleFileChange  (e){
    
    this.setState({
      files : e.target.files
    })
  };

  async createCategory(e) {
    e.preventDefault();
    if(this.state.saving)return false
    this.setState({saving:true});
    let {files, name, category_id} = this.state;
    const filedata = new FormData();
    let file = files[0];
    filedata.append(`image`, file, file.name);
    filedata.append('name', name) 
    
    

    let url = `${common.bs}/admin_categories.php?action=create&token=${this.props.token}`;
    const response = await axios.post(url, filedata);
    let data = response.data;
    this.setState({
      saving:false
    })
    if (data.ok) {
      this.setState({
        name:'',category_id:''
      });
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: data.msg || 'Category successfully.'}],
      });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to create Category'}],
      });
    }
  }

  render() {
    const {categories} = this.state;

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h2>{'New Category'}</h2>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.createCategory.bind(this)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Category Name</label>
                    <input
                      value={this.state.name}
                      type="text"
                      required
                      name="name"
                      id="name"
                      onChange={e => {
                        this.setState({
                          [e.target.id]: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
        
                 
                  
                
                  <div className="form-group">
                    <label>Image</label>

                    <input
                      value={this.state.image}
                      required
                      name="image"
                      id="image"
                      type='file'
                      accept="image/*" 
                      onChange={this.handleFileChange.bind(this)}
                      className="form-control"
                    />
                  </div>
  

 
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={this.state.saving ? 'please wait...' : "Create New Category"}
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(CategoryNew);
