import moment from 'moment';
import {Cookies} from 'react-cookie';
import common from '../components/common';
import history from '../history';


export function login(email, password, role) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch(common.bs+'/auth.php?action=login', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        email: email,
        password: password,
        role : role
      })
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          // console.log(json);return;
          dispatch({
            type: 'LOGIN_SUCCESS',
            token: json.data.token,
            role :role,
            user: json.data.user
          });
          window.localStorage.setItem("koni_userInfo", JSON.stringify(json.data.user));
          window.localStorage.setItem("koni_userRole", json.data.role);
          window.localStorage.setItem("koni_userToken", json.data.token);

          if(json.data.user.role==='avbob')
          {
            // history.push('/companies');
            window.location.href="/my-jobcards";
          }else if(json.data.user.role==='supplier')
          {
            // history.push('/companies');
            window.location.href="/my-account";
          }else if(json.data.user.role==='admin')
          {
            // history.push('/companies');
            window.location.href="/jobcards";
          }
          
        });
      } else {
        return response.json().then(json => {
          dispatch({
            type: 'LOGIN_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}

// export function signup(name, email, password) {
//   return dispatch => {
//     dispatch({
//       type: 'CLEAR_MESSAGES'
//     });
//     return fetch('/signup', {
//       method: 'post',
//       headers: {'Content-Type': 'application/json'},
//       body: JSON.stringify({name: name, email: email, password: password})
//     }).then(response => {
//       return response.json().then(json => {
//         if (response.ok) {
//           dispatch({
//             type: 'SIGNUP_SUCCESS',
//             token: json.token,
//             user: json.user
//           });
//           history.push('/');
//           Cookies.setCookie('token', json.token, {
//             expires: moment()
//               .add(1, 'month')
//               .toDate()
//           });
//         } else {
//           dispatch({
//             type: 'SIGNUP_FAILURE',
//             messages: Array.isArray(json) ? json : [json]
//           });
//         }
//       });
//     });
//   };
// }

export function logout() {
  // Cookies.removeCookie('token');
  localStorage.removeItem('koni_userInfo');
  localStorage.removeItem('koni_userRole');
  localStorage.removeItem('koni_userToken');
  window.location.href = '/';
  return {
    type: 'LOGOUT_SUCCESS'
  };
}

export function forgotPassword(email, loginAs, cb) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch(common.bs+'/auth.php?action=reset', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({email: email, loginAs:loginAs})
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          cb && cb();
          dispatch({
            type: 'FORGOT_PASSWORD_SUCCESS',
            messages: [json]
          });
        });
      } else {
        return response.json().then(json => {
          cb && cb();
          dispatch({
            type: 'FORGOT_PASSWORD_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}

export function resetPassword(password, confirm, pathToken) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch(`/reset/${pathToken}`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        password: password,
        confirm: confirm
      })
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          history.push('/login');
          dispatch({
            type: 'RESET_PASSWORD_SUCCESS',
            messages: [json]
          });
        });
      } else {
        return response.json().then(json => {
          dispatch({
            type: 'RESET_PASSWORD_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}

export function updateProfile(role, data, token) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch(common.bs+'/'+role+'_actions.php?action=saveMe', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({data:data, token: token})
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          // alert('dispatching')
          dispatch({
            type: 'UPDATE_USER_INFO',
            data : data
          })
          window.localStorage.setItem("koni_userInfo", JSON.stringify(json.user));
          dispatch({
            type: 'UPDATE_PROFILE_SUCCESS',
            messages: [json]
          });
        });
      } else {
        return response.json().then(json => {
          dispatch({
            type: 'UPDATE_PROFILE_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}

export function changePassword(role, data, token) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch(common.bs+'/'+role+'_actions.php?action=saveMe', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({data:data, token: token})
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          dispatch({
            type: 'CHANGE_PASSWORD_SUCCESS',
            messages: [json]
          });
        });
      } else {
        return response.json().then(json => {
          dispatch({
            type: 'CHANGE_PASSWORD_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}
