
import React, { useState } from "react";
import common from './common';
const AssignCategories = ({categories, onSelected}) =>{
  const[selectedList , setSelectedList] = useState([])

  const saveSupplierList = (vl)=>{
    let sel  = JSON.parse(JSON.stringify(vl));
    onSelected && onSelected(sel);
  }

  // const saveSupplierList = async ()=>{
  //   onStart();
  //   let url =`${common.bs}/admin_orders.php?action=assignCategories&token=${token}`
  //   let resp  = await axios.post(url, {
  //     data : {
  //       suppliers : selectedList.join(','),
  //       orderId : order.id
  //     },
  //     token : token
  //   })
  //   if(resp.data.ok)
  //   {
  //     onSelected(resp.data);
  //   }
  // }


  return (
    <div>
      <h4>Choose Categories</h4>
      {
        categories.map((item, i)=>{
          return(<div key={item.id}>
          <label className="input_lbl">
            <input type={'checkbox'} checked={selectedList.indexOf(''+item.id) > -1} onChange={()=>{
              
              let rmp = JSON.parse(JSON.stringify(selectedList));
              if(rmp.indexOf(item.id) > -1)
              {

                rmp.splice(rmp.indexOf(item.id), 1);
              }else{
                rmp.push(''+item.id);
              }
              setSelectedList(rmp);
              saveSupplierList(rmp);

            }} /> <img src={common.bs + '/..'+item.image} className='product-imga' /> {item.name} 
          </label></div>);
        })
      }
<br />
<hr />
      {/* <button className="btn btn-primary" onClick={saveSupplierList}>Save Suppliers</button> */}
    </div>
  )
}

export default AssignCategories;