import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import Fetcher from "../../actions/Fetcher";
import Messages from "../../components/Messages";
import AssignSuppliers from "../../components/AssignSuppliers";
import common from "../../components/common";
import axios from "axios";
import { useParams } from "react-router-dom";
import Progress from '../../components/Progress';
import utils from '../../utils';
const {
  getFancyStatus,
  findSupplier,
  getStatusColor,
  getOrderDataAr,
  getFancyQuoteStatus,
  getOrderData
} = utils;
let baseOrderUrl = `${common.bs}/supplier_actions.php`;



const lbls = {
  addr_name : "Name",
  addr_line_1: 'Address Line 1',
  addr_city: 'City',
  addr_state: 'State',
  addr_postal: 'Postal',
  

}


const Box = ({ lbl, val }) => {
  return (
    <div className="flex-row">
      <strong>{lbl}</strong>
      <span style={{ textAlign: "right" }}>{val}</span>
    </div>
  );
};

const OrderDetailsForSupplier = (props) => {
  const [p, setP] = useState(0);
  const [order, setOrder] = useState({});
  const [providesDelivery, setProvidesDelivery] = useState('no');
  const [user, setUser] = useState({});
  const [quote, setQuote] = useState([]);
  const [quote_text, setQuoteText] = useState('');
  const [amount, setAmount] = useState('');
  // const [jobcard, setJobCard] = useState({});
  // const [avbob, setAvbob] = useState({});
  const [suppliers, setSuppliers] = useState({});
  const params = useParams();
  // console.log(params);

  useEffect(() => {
    async function getDetails() {
      const resp = await axios.get(
        `${common.bs}/supplier_actions.php?action=details&id=${params.orderId}&token=${props.token}`
      );
      setOrder(resp.data.order);
      // setAvbob(resp.data.avbob);
      // setJobCard(resp.data.jobcard);
      setUser(resp.data.user_info);
      setSuppliers(resp.data.suppliers);
      setQuote(resp.data.assigned? resp.data.assigned.length > 0 ? resp.data.assigned[0] : null : null);
    }
    getDetails();
  }, [p]);

  useEffect(() => {
    setP(1);
  });

  const orderData = order.id ? getOrderData(order.order_data) : {};
  const orderDataAr = order.id ? getOrderDataAr(orderData) : [];

  
  const addressData = order.id ? getOrderData(order.address_data) : {};
  const addressDataAr = order.id ? getOrderDataAr(addressData) : [];

  const submitQuote = async (e)=>
  {
    e.preventDefault();
    let resp  = await axios.post(baseOrderUrl+'?action='+'submitQuote', {
      token :props.token,
      data : {
        orderId : order.id,
        amount : amount, 
        providesDelivery: providesDelivery,
        quote: quote_text,
        quoteId: quote.id
      }
    });
    if(resp.data.ok)
    {
      setP(p + 1);
    }
  }
  

  // console.log(avbob);
  return (
    <div className="container-fluid dashboard-content">
      <h2>{"Order details - #" + params.orderId}</h2>
      <Messages messages={props.messages} />
      <div className="row">
        <div className="col-md-4  ">
          <div className="bg-white p-3">
            <div className="flex-box">
              <span style={{ textAlign: "right", float: "right" }}>
                <span className={"badge badge-" + getStatusColor(order.status)}>
                  {order.status == 'with_suppliers' ? 'Received' : getFancyStatus(order.status)}
                </span>
              </span>
              <h4>Order Details</h4>

              <hr />
              <img src={common.bs + '/..'+orderData.image}  style={{width:'100%'}}/>
              <h3>{orderData.name}</h3>
{/* 
              {orderDataAr.map((item, i) => {
                return <Box key={item.key} lbl={item.key} val={item.val} />;
              })} */}
            </div>
          </div>
        </div>

        <div className="col-md-4 ">
          <div className="bg-white p-3  mb-3">
            <div className="flex-box">
              <h4>Client Details</h4>
              <hr />
              {
                user ? 
                order && order.status!='cancelled' && order.status!='cancel' ? 
                <>
                  <Box lbl={"Client ID"} val={user.id} />
                  <Box lbl={"Name"} val={user.first_name + " " + user.last_name} />
                  <Box lbl={"Phone Number"} val={user.phone_number} />
                  <Box lbl={"Email"} val={user.email} />
                </>:
                <div className="alert alert-warning">User information is not visible as order is cancelled.</div>
                :
                <div className="alert alert-warning">User information is visible only after quote is accepted.</div>
              }
              
            </div>
          </div>
          <div className="bg-white p-3 ">
            <div className="flex-box">
              <h4>Address Details</h4>
              <hr />
              {
                user ? 
                order && order.status!='cancelled' && order.status!='cancel' ? 
                <>
                     {addressDataAr.map((item, i) => {
                return <Box key={item.key} lbl={lbls[item.key] ? lbls[item.key] : item.key} val={item.val} />;
              })}
              
                </>:
                <div className="alert alert-warning">Address information is not visible as order is cancelled.</div>
                :
                <div className="alert alert-warning">Address information is visible only after quote is accepted.</div>
              }
             
              {/* <Box lbl={"Name"} val={'****'} />
              <Box lbl={"Address"} val={'****'} />
              <Box lbl={"City"} val={'****'} />
              <Box lbl={"State"} val={'****'} />
              <Box lbl={"Postal"} val={'****'} /> */}
              
            </div>
          </div>
        </div> 
        <div className="col-md-4  ">
          <div className="bg-white p-3">
            <span style={{ textAlign: "right", float: "right" }}>
              <span className={"badge badge-" + getStatusColor(quote.status)}>
                {getFancyQuoteStatus(quote.status)}
              </span>
            </span>
            <h4>Quote Information</h4>
            <hr />
            <div>
              {
                quote.status == 'assigned' ? 
                <form onSubmit={submitQuote}>
                  <div className="form-group">
                    <label>Quote Amount</label>
                    <input type="number" step="0.1" min="0.1"  value={amount} onChange={( e)=>{
                      setAmount(e.target.value);
                    }} id="amount" name="amount" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Delivery</label>
                    <select className="form-control" value={providesDelivery} onChange={(e)=>{
                      setProvidesDelivery(e.target.value)
                    }}>
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                    
                  </div>
                  
                  <div className="form-group">
                  <label>Quote Remarks</label>
                      <textarea value={quote_text} onChange={( e)=>{
                      setQuoteText(e.target.value);
                    }}  id="quote_text" name="quote_text" className="form-control" ></textarea>
                    </div>
                    <p>
                      <button type="submit" className="btn btn-success">Submit Quotation</button>
                    </p>

                </form>  :
                
                <div className="flex-box">
                <Box lbl="Amount" val={quote.amount} />
                <Box lbl="Quote" val={quote.quote} />
                <Box lbl="Provide Delivery?" val={quote.provides_delivery} />
                </div>

              }
            </div>
            
          </div>
        </div>
        
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(OrderDetailsForSupplier);
