const initialState = {
  token: null,
  user: {},
  role: 'anon',
};

export default function auth(state = initialState, action) {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, {hydrated: true});
  }
  switch (action.type) {
    case 'LOGIN_SUCCESS':
    case 'SIGNUP_SUCCESS':
    case 'OAUTH_SUCCESS':
      
      return Object.assign({}, state, {
        token: action.token,
        user: action.user,
        role: action.role
      });

      case 'UPDATE_USER_INFO':
        let user  = state.user;
        user = Object.assign({}, user, action.data );
        return Object.assign({}, state, {
          user : user
        });


    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
